import { moneyFormatter } from "./utils";

export type TableDataPoint = {
  name: string;
  pension: number;
  ISA: number;
  GIA: number;
  total: number;
  savings: number;
  spendings: number;
};

type Props = {
  data: TableDataPoint[];
}

export default function FireUkTable(props: Props) {
  const rows = props.data.map(value => {
    return (
      <tr className="border-[1px] border-gray-200">
        <td>{value.name}</td>
        <td>{moneyFormatter(value.pension)}</td>
        <td>{moneyFormatter(value.ISA)}</td>
        <td>{moneyFormatter(value.GIA)}</td>
        <td>{moneyFormatter(value.total)}</td>
        <td>{moneyFormatter(value.savings)}</td>
        <td>{moneyFormatter(value.spendings)}</td>
      </tr>
    )
  });

  return (
    <div className="w-[100%] overflow-x-auto">


      <table className="table-fixed w-[100%] min-w-[48rem] max-w-[83rem] h-[50rem] text-center">
        <thead className="border-[1px] border-gray-200">
          <tr>
            <th className="p-4">Age</th>
            <th className="p-4">Pension</th>
            <th className="p-4">ISA</th>
            <th className="p-4">GIA</th>
            <th className="p-4">Total</th>
            <th className="p-4">Savings</th>
            <th className="p-4">Spendings</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </table>
    </div>
  );
}