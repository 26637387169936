
import React, { useEffect, useState } from 'react';
import './App.css';
import FireUkChart, { ChartDataPoint } from './FireUkChart';
import FireUkTable, { TableDataPoint } from './FireUkTable';
import InvestmentInput, { InvestmentWithWeights, MoneyInput, NumberInput, PercentageInput } from './InvestmentInput';
import { BrowserRouter, Route, Routes, useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faShareNodes, faQuestion } from '@fortawesome/free-solid-svg-icons';

type CalcInputs = {
  // The Pension pot `saving` should include both employer and personal.
  // It shouldn't include the HMRC tax relief, which we'll calculate ourselves.
  pension: InvestmentWithWeights;
  isa: InvestmentWithWeights;
  gia: InvestmentWithWeights;

  // Savings are specified in InvestmentWithWeights. So they are not defined
  // below.

  currentAge: number;
  retirementAge: number;
  pensionAccessAge: number;

  // How much- in GBP- is spent once retired. This is in today's money and
  // will be scaled up with inflation. It's also yearly.
  retirementSpending: number;

  pensionTaxRelief: number; // Percentage (either 20%, 40% or 45%).

  safeWithdrawalRate: number; // Percentage (typically 4%).
  inflationRate: number; // Percentage (typically 3-5%).
  incomeGrowthRate: number; // Percentage.

  endAge: number;

  // Just like in WalletBurst's calculator, we by default use inflation-adjusted
  // rate of returns. In other words, all the numbers shown in the chart are
  // in today's dollars.
  //
  // Setting this bool to `true` will mean that the numbers shown in the chart
  // are not adjusted for inflation. It's what you would expect to see in real
  // life. What does get adjusted instead is your spending.
  //
  // NOTE: This is experimental and is mainly here to help understand how
  // these calculation strategies differ.
  useFuturePounds: boolean;
};

function growReturns(
  pot: InvestmentWithWeights, inflationRate: number,
  useFuturePounds: boolean
) {
  // Grow the total by the returns.
  const infRate = useFuturePounds ? 0 : inflationRate;
  pot.total +=
    (pot.bonds.allocation/100) *
    ((pot.bonds.return-infRate)/100) *
    pot.total
  pot.total +=
    (pot.stocks.allocation/100) *
    ((pot.stocks.return-infRate)/100) *
    pot.total
  pot.total +=
    (pot.crypto.allocation/100) *
    ((pot.crypto.return-infRate)/100) *
    pot.total
  pot.total +=
    (pot.cash.allocation/100) *
    ((pot.cash.return-infRate)/100) *
    pot.total
  pot.total +=
    (pot.other.allocation/100) *
    ((pot.other.return-infRate)/100) *
    pot.total
}

function adjustSavings(
  pot: InvestmentWithWeights,
  incomeGrowthRate: number, inflationRate: number,
  useFuturePounds: boolean
) {
  // The WalletBurst fire calc uses:
  //
  //   Math.pow(1 + 0.01 * (incomeGrowthRate - inflationRate), i - currentAge - 1)
  //
  // It also does this on the "Post-tax salary" and then always subtracts the
  // *same* annual spending from the result of the above formula. This results
  // in lower than annual savings than in our calculator.
  //
  // I think that's fine though.
  const infRate = useFuturePounds ? 0 : inflationRate;
  pot.saving += ((incomeGrowthRate-infRate)/100) * pot.saving;
}

function spendPot(pot: InvestmentWithWeights, amount: number): number {
  if (amount <= 0) {
    return 0;
  }
  // Returns the remainder of `amount` that could not be spent because
  // pot has run out of money.
  if (amount > pot.total) {
    const result = amount - pot.total;
    pot.total = 0;
    return result;
  } else {
    pot.total -= amount;
    return 0;
  }
}

type CalculateResult = {
  chart: ChartDataPoint[],
  table: TableDataPoint[]
};

function calculate(inputs: CalcInputs): CalculateResult {
  let modified = structuredClone(inputs);
  let chart: ChartDataPoint[] = [];
  let table: TableDataPoint[] = [];

  let currentAge = inputs.currentAge;
  while (currentAge <= inputs.endAge) {
    chart.push({
      name: currentAge.toString(),
      pension: modified.pension.total,
      ISA: modified.isa.total,
      GIA: modified.gia.total,
      total: modified.pension.total + modified.isa.total + modified.gia.total
    });

    // Simulate growth, savings, inflation and taking money away after retirement.

    if (currentAge < modified.retirementAge) {
      // Capture data before any adjustments.
      table.push({
        ...chart[chart.length-1],
        savings: modified.gia.saving*12 + modified.isa.saving*12 + modified.pension.saving*12,
        spendings: 0
      });

      // Grow the total by the real returns (adjusted for inflation)
      growReturns(modified.pension, modified.inflationRate, inputs.useFuturePounds);
      growReturns(modified.isa, modified.inflationRate, inputs.useFuturePounds);
      growReturns(modified.gia, modified.inflationRate, inputs.useFuturePounds);
      // Add on the savings
      modified.pension.total += modified.pension.saving*12;
      modified.isa.total += modified.isa.saving*12;
      modified.gia.total += modified.gia.saving*12;

      // Pension tax relief.
      // const reliefRatio = inputs.pensionTaxRelief/100;
      // const pensionSaving = modified.pension.saving*12;
      // const relief = (pensionSaving/(1.0-reliefRatio))-pensionSaving;
      // modified.pension.total += relief;

    } else {
      // Capture data before any adjustments.
      table.push({
        ...chart[chart.length-1],
        savings: 0,
        spendings: modified.retirementSpending
      });

      // Grow the total by the returns.
      growReturns(modified.pension, modified.inflationRate, inputs.useFuturePounds);
      growReturns(modified.isa, modified.inflationRate, inputs.useFuturePounds);
      growReturns(modified.gia, modified.inflationRate, inputs.useFuturePounds);

      // Spend the money.
      //
      // Taking money from taxed accounts first is best.
      // https://old.reddit.com/r/FIREUK/comments/17evuot/i_built_a_uk_fire_calculatorvisualiser/k664tki/
      //
      // https://www.brewin.co.uk/insights/isa-or-pension-which-should-i-take-first
      if (currentAge >= modified.pensionAccessAge) {
        let remainder = spendPot(modified.gia, modified.retirementSpending);
        remainder = spendPot(modified.pension, remainder);
        remainder = spendPot(modified.isa, remainder);

        if (remainder > 0) {
          modified.pension.total -= remainder;
        }
      } else {
        // We can only get money from SIPP/GIA.
        let remainder = spendPot(modified.gia, modified.retirementSpending);
        remainder = spendPot(modified.isa, remainder);

        if (remainder > 0) {
          modified.gia.total -= remainder;
        }
      }
    }

    // Inflating the retirement spend as well.
    if (inputs.useFuturePounds) {
      modified.retirementSpending +=
        (modified.inflationRate / 100) * modified.retirementSpending;
    }

    // And inflating/deflating the savings.
    adjustSavings(
      modified.pension, modified.incomeGrowthRate, modified.inflationRate,
      inputs.useFuturePounds
    );
    adjustSavings(
      modified.isa, modified.incomeGrowthRate, modified.inflationRate,
      inputs.useFuturePounds
    );
    adjustSavings(
      modified.gia, modified.incomeGrowthRate, modified.inflationRate,
      inputs.useFuturePounds
    );

    currentAge += 1;
  }

  return { chart, table };
}

function parseUrlInvestmentWithWeights(
  data: string | null, defaultTotal: number, defaultSaving: number
): InvestmentWithWeights {
  // "<total>_<saving>_<bonds_alloc>_<bonds_return>..."
  // All params ordered as below, and separate by _.
  //
  // Bonds first and stocks last to make them easy to identify.
  // https://perishablepress.com/stop-using-unsafe-characters-in-urls/
  let parts = (data ?? "").split("_");
  let result: InvestmentWithWeights = {
    total: Number(parts[0] || defaultTotal),
    saving: Number(parts[1] || defaultSaving),
    bonds: {
      allocation: Number(parts[2] || 15),
      return: Number(parts[3] || 5),
    },
    cash: {
      allocation: Number(parts[4] || 0),
      return: Number(parts[5] || 0.5),
    },
    crypto: {
      allocation: Number(parts[6] || 0),
      return: Number(parts[7] || 2),
    },
    other: {
      allocation: Number(parts[8] || 0),
      return: Number(parts[9] || 0)
    },
    stocks: {
      allocation: Number(parts[10] || 85),
      return: Number(parts[11] || 8)
    }
  };

  return result;
}

function investmentToString(investment: InvestmentWithWeights): string {
  const parts = [
    investment.total,
    investment.saving,
    investment.bonds.allocation,
    investment.bonds.return,
    investment.cash.allocation,
    investment.cash.return,
    investment.crypto.allocation,
    investment.crypto.return,
    investment.other.allocation,
    investment.other.return,
    investment.stocks.allocation,
    investment.stocks.return,
  ]
  return parts.join("_");
}

function genUrlParams(inputs: CalcInputs): URLSearchParams {
  return new URLSearchParams({
    currentAge: inputs.currentAge.toString(),
    incomeGrowthRate: inputs.incomeGrowthRate.toString(),
    inflationRate: inputs.inflationRate.toString(),
    retirementAge: inputs.retirementAge.toString(),
    retirementSpending: inputs.retirementSpending.toString(),
    safeWithdrawalRate: inputs.safeWithdrawalRate.toString(),
    pensionAccessAge: inputs.pensionAccessAge.toString(),
    endAge: inputs.endAge.toString(),
    pension: investmentToString(inputs.pension),
    isa: investmentToString(inputs.isa),
    gia: investmentToString(inputs.gia),
    useFutureMoney: inputs.useFuturePounds.toString()
  });
}

function FireUK() {
  let [urlParams, setUrlParams] = useSearchParams();
  let [clipboardChanged, setClipboardChanged] = useState<boolean>(false);
  let [helpExpanded, setHelpExpanded] = useState<boolean>(false);

  const defaultInputs = {
    currentAge: Number(urlParams.get("currentAge") ?? 30),
    incomeGrowthRate: Number(urlParams.get("incomeGrowthRate") ?? 3),
    inflationRate: Number(urlParams.get("inflationRate") ?? 3),
    pensionTaxRelief: 40, // XXX
    retirementAge: Number(urlParams.get("retirementAge") ?? 45),
    retirementSpending: Number(urlParams.get("retirementSpending") ?? 40000),
    safeWithdrawalRate: Number(urlParams.get("safeWithdrawalRate") ?? 4),
    pensionAccessAge: Number(urlParams.get("pensionAccessAge") ?? 55),
    endAge: Number(urlParams.get("endAge") ?? 90),
    pension: parseUrlInvestmentWithWeights(urlParams.get("pension"), 60000, 1000),
    isa: parseUrlInvestmentWithWeights(urlParams.get("isa"), 30000, 1000),
    gia: parseUrlInvestmentWithWeights(urlParams.get("gia"), 10000, 500),
    useFuturePounds: urlParams.get("useFutureMoney") === "true"
  }

  const [age, setAge] = useState<number>(defaultInputs.currentAge);
  const [retirementAge, setRetirementAge] = useState<number>(defaultInputs.retirementAge);
  const [pensionAccessAge, setPensionAccessAge] = useState<number>(defaultInputs.pensionAccessAge);
  const [retirementSpending, setRetirementSpending] = useState<number>(defaultInputs.retirementSpending);
  const [endAge, setEndAge] = useState<number>(defaultInputs.endAge);
  const [incomeGrowth, setIncomeGrowth] = useState<number>(defaultInputs.incomeGrowthRate);
  const [inflationRate, setInflationRate] = useState<number>(defaultInputs.inflationRate);
  const [safeWithdrawalRate, setSafeWithdrawalRate] = useState<number>(defaultInputs.safeWithdrawalRate);

  const [pensionInvestments, setPensionInvestments] = useState<InvestmentWithWeights>(defaultInputs.pension);
  const [giaInvestments, setGiaInvestments] = useState<InvestmentWithWeights>(defaultInputs.gia);
  const [isaInvestments, setIsaInvestments] = useState<InvestmentWithWeights>(defaultInputs.isa);

  const makeInputs = () => {
    let inputs: any = {};
    inputs.useFuturePounds = defaultInputs.useFuturePounds;
    inputs.currentAge = age;
    inputs.retirementAge = retirementAge;
    inputs.pensionAccessAge = pensionAccessAge;
    inputs.retirementSpending = retirementSpending;
    inputs.endAge = endAge;
    inputs.incomeGrowthRate = incomeGrowth;
    inputs.inflationRate = inflationRate;
    inputs.safeWithdrawalRate = safeWithdrawalRate;

    inputs.pension = pensionInvestments;
    inputs.gia = giaInvestments;
    inputs.isa = isaInvestments;

    return inputs;
  };

  // https://stackoverflow.com/a/56247483/492186
  // Set up callback whenever the settings state changes to update URL in address bar.
  useEffect(
    () => {
      // TODO: Don't set this when inputs are default?
      // setUrlParams(genUrlParams(makeInputs()));
      setClipboardChanged(false);
    },
    [
      age, retirementAge, pensionAccessAge, retirementSpending, endAge,
      incomeGrowth, inflationRate, safeWithdrawalRate, pensionInvestments,
      giaInvestments, isaInvestments
    ]
  )

  const inputs = makeInputs();
  const calculations = calculate(inputs);

  const clipboard = clipboardChanged ? (
    <p className={"p-1 border rounded-md bg-green-200 border-green-400 text-green-800 ml-4 mt-4 px-3 inline-block"}>
      <FontAwesomeIcon icon={faCheck} className="mr-2"></FontAwesomeIcon>
      Link with calculator inputs copied to your clipboard
    </p>
  ) : null;

  const onShareClick = () => {
    let url = new URL(window.location.toString());
    url.search = genUrlParams(makeInputs()).toString();
    navigator.clipboard.writeText(url.toString()).then(
      () => {
        setClipboardChanged(true);
      },
      (err) => {
        setClipboardChanged(false);
        alert("Failed to copy: " + err);
      },
    );
  };

  const hiddenText = !helpExpanded ? (
    <>
      <span>... </span>
      <span className='no-underline hover:underline text-blue-600 cursor-pointer' onClick={() => setHelpExpanded(true)}>See More</span>
      &nbsp;
    </>
  ) : (
    <>
      &nbsp;the graph will automatically
      update, giving you a sense of how long your retirement pot will last
      for.
    </>
  );

  return (
    <>
    <div className='m-4 overflow-hidden'>
      <h1 className='text-3xl font-bold mb-4 inline-block mr-4'>Financial Independence / Retire Early (FIRE) Calculator for the UK 🇬🇧</h1>
      <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded inline-block mr-2" onClick={onShareClick}>
        <FontAwesomeIcon icon={faShareNodes} className="mr-2"></FontAwesomeIcon>
        Share
      </button>
      <a href="#help" onClick={() => setHelpExpanded(true)}>
        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded inline-block">
          <FontAwesomeIcon icon={faQuestion} className="mr-2"></FontAwesomeIcon>
          Help
        </button>
      </a>
      {clipboard}
      <div className="mt-4">
        <NumberInput name={"Current Age"} setter={setAge} value={age}  inline={true} size={8}/>
        <NumberInput name={"Retirement Age"} setter={setRetirementAge} value={retirementAge}  inline={true} size={12}/>
        <NumberInput name={"Pension Access Age"} setter={setPensionAccessAge} value={pensionAccessAge}  inline={true} size={15}/>
        <NumberInput name={"End Age"} setter={setEndAge} value={endAge}  inline={true} size={6}/>
        <MoneyInput name={"Retirement Spending"} setter={setRetirementSpending} value={retirementSpending}  inline={true} size={16}/>
        <PercentageInput name={"Income growth rate"} setter={setIncomeGrowth} value={incomeGrowth}  inline={true}  size={15}/>
        <PercentageInput name={"Inflation rate"} setter={setInflationRate} value={inflationRate}  inline={true}  size={10}/>
        <PercentageInput name={"Safe withdrawal rate"} setter={setSafeWithdrawalRate} value={safeWithdrawalRate}  inline={true}  size={15}/>
      </div>
      <h3 className='text-xl mb-2 text-[#619f78]'>⬤ Pension</h3>
      <InvestmentInput value={inputs.pension} setter={setPensionInvestments}/>
      <div className='w-full h-2 bg-[#619f78] -mt-6 mb-4 rounded-full opacity-60 hover:opacity-100 max-w-[82rem]'></div>
      <h3 className='text-xl mb-2 text-[#8884d8]'>⬤ ISA (Individual Savings Accounts)</h3>
      <InvestmentInput value={inputs.isa} setter={setIsaInvestments}/>
      <div className='w-full h-2 bg-[#8884d8] -mt-6 mb-4 rounded-full opacity-60 hover:opacity-100 max-w-[82rem]'></div>
      <h3 className='text-xl mb-2 text-[#ffc658]'>⬤ <span className='text-[#eeba51]'>GIA (General Investment Accounts)</span></h3>
      <InvestmentInput value={inputs.gia} setter={setGiaInvestments}/>
      <div className='w-full h-2 bg-[#ffc658] -mt-6 mb-4 rounded-full opacity-60 hover:opacity-100 max-w-[82rem]'></div>
      <FireUkChart data={calculations.chart} swr={safeWithdrawalRate} annualSpending={retirementSpending}/>
      <FireUkTable data={calculations.table}/>
      <div className="max-w-2xl">
        <h2 className='text-2xl my-4' id="help">Help! How do I use this?</h2>
        <p>
          Start by filling in the input values at the top of the calculator
          with your financial information. As you do{hiddenText}
        </p>
        { helpExpanded ? (
            <>
            <br/>
            <p>
              You can leave the <b>inflation rate</b>,
              as well as the <b>stocks and bond returns</b> as they are. They have been
              set to a conservative value based on historical data. If you are
              pursuing FIRE, you should feel encouraged to read up on these variables
              and adjust them further based on how pessimistic you want the calculation
              here to be.
            </p>
            <br/>
            <p>
              There is no need to adjust any inputs for inflation as the calculator
              takes care of this for you. This means that for example
              your <b>stocks return</b> should be set to the average stock market return
              NOT adjusted for inflation.
              It also means that your <b>retirement spending</b> does not need to be adjusted,
              the calculator will inflate it every year based on
              the <b>inflation rate</b> you specify.
            </p>
            <br/>
            <p>
              Currently the model used in this calculator does not take <b>taxes</b>
              into account. This means that money from your pension in retirement
              is taken without any tax adjustments. This may give an optimistic
              bias to the visualisation. But on the other hand, the calculator
              also does not add on pension tax relief. This should change in
              the future as we plan to implement better tax modelling. In the
              meantime you may wish to manually adjust your pension growth to
              account for this.
            </p>
            <br/>
            <p>
              In order to <b>save</b> your inputs, click the share button above. This
              will copy a hyperlink to your clipboard which contains all your inputs
              which you can then save (you can email it to yourself or store it in a document).
            </p>
            <br/>
            <p>
              Note that this tool is provided as-is and there are no guarantees as to
              its accuracy. Feedback and bug reports welcome (contact details
              <a href="https://picheta.me" className='no-underline hover:underline text-blue-600'> here</a>).
              <br/>
              <span className='no-underline hover:underline text-blue-600 cursor-pointer' onClick={() => setHelpExpanded(false)}> See Less</span>
            </p>
            </>
          ) : null
        }
        <h2 className='text-2xl my-4'>FAQ</h2>
        <h3 className='text-xl my-4'>How are withdrawals balanced across the different pots?</h3>
        <p>
          Once the model reaches your specified <b>retirement age</b>, it starts by
          withdrawing the retirement spend from your GIA, if your GIA runs out of funds
          then it begins withdrawing from your pension (assuming the pension access age was reached),
          then once that runs out from your ISA.
        </p>
        <br/>
        <p>
          This is done so that withdrawals are made in the most tax efficient
          manner. That said, the model does not currently model taking away
          tax during withdrawals. This is planned for the future.
        </p>
        <h3 className='text-xl my-4'>How are taxes accounted for on GIA/Pension withdrawals?</h3>
        <p>
          Currently they are not. Pension tax relief is also not modelled. This will change
          in the future.
        </p>
        <h3 className='text-xl my-4'>Spending is not increased by inflation, what gives?</h3>
        <p>
          The growth of your investments already takes inflation into account, so the spend does not
          also need to be adjusted.
        </p>
        <h3 className='text-xl my-4'>What is income growth rate used for?</h3>
        <p>
          It's used to grow your monthly savings. Take a look at the table to
          see how the "Savings" column changes, but be sure the <b>income growth rate</b> is
          not the same as the <b>inflation rate</b> as these can cancel out.
        </p>
        <h3 className='text-xl my-4'>What is the <b>Safe Withdrawal Rate</b>?</h3>
        <p>
          It is not used in the calculation of the model. Instead it is only
          used to calculate the position of the "FIRE Number" line on the chart
          (the blue dashed line).
        </p>
        <h3 className='text-xl my-4'>Is the state pension factored in?</h3>
        <p>
          Not at the moment. But this is a planned feature.
        </p>
        <h3 className='text-xl my-4'>What's the tech stack used for this tool?</h3>
        <p>
          React + Tailwind + Recharts + Cloudflare Pages
        </p>
        <h2 className='text-2xl my-4'>Changelog</h2>
        <h3 className='text-lg my-4'>2023-10-28</h3>
        <ul>
          <li>Added ISA/GIA expanded forms to headings.</li>
        </ul>
        <h3 className='text-lg my-4'>2023-10-25</h3>
        <ul>
          <li>Added FAQ and changelog.</li>
          <li>Changed the order of spend withdrawals: now pension is prioritised before ISA.</li>
        </ul>
        <h3 className='text-lg my-4'>2023-10-24</h3>
        <ul>
          <li>GIA is now spent before ISA/Pension.</li>
          <li>Fixed decimal point handling in percentage inputs.</li>
        </ul>
        <h3 className='text-lg my-4'>2023-10-23</h3>
        <p>
          Initial release
        </p>
      </div>
    </div>
    <footer className='h-4 w-full border-t-2 border-gray-200 text-center'>
      <h4 className='text-sm py-4 text-gray-400'>© FIRE UK Calculator 2023</h4>
    </footer>
    </>
  );
}

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/uk" element={<FireUK/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
