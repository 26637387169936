import { useEffect, useState } from "react";
import { moneyFormatter } from "./utils";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

export type Investment = {
  allocation: number; // Percentage (0-100)
  return: number; // Percentage, not adjusted for inflation
}

export type InvestmentWithWeights = {
  total: number; // Current amount that you have saved and invested in GBP
  saving: number; // How much- in GBP- is saved each MONTH and invested
  // Pre-defined categories for simplicity.
  stocks: Investment;
  bonds: Investment;
  cash: Investment;
  crypto: Investment;
  other: Investment;
};

type Props = {
  value: InvestmentWithWeights;
  setter: React.Dispatch<React.SetStateAction<InvestmentWithWeights>>;
}

type MoneyProps = {
  name: string;
  setter: React.Dispatch<React.SetStateAction<number>> | ((val: number) => void);
  value: number;
  color?: string;
  inline?: boolean;
  size?: number;
  mt?: number;
}

const onChange = (ev: any, props: MoneyProps, setVal: React.Dispatch<React.SetStateAction<string>>, isPercentage: boolean) => {
  const stripped = ev.target.value.replace(/[^-.0-9]/g, '');
  const num = Number.parseFloat(stripped);
  const filtered = Number.isNaN(num) ? 0 : num;
  props.setter(filtered);
  // `stripped` for PercentageInput so that typing in `.` works, `filtered`
  // for others so that removing all numbers from a MoneyInput doesn't turn to NaN.
  setVal(isPercentage ? stripped : filtered.toString());
}

export function NumberInput(props: MoneyProps) {
  const [val, setVal] = useState<string>(props.value.toString());

  return <div className={"relative mb-4 mr-4 " + (props.inline ? " inline-block " : "")}>
    <input type="text" className="peer border placeholder:text-transparent rounded-md p-2" placeholder="name"
        onChange={(ev) => onChange(ev, props, setVal, false)} value={val} size={props.size ?? 14}/>
    <label htmlFor="text" className="absolute left-0 ml-1 -translate-y-3 bg-white px-1 text-sm duration-100 ease-linear peer-placeholder-shown:translate-y-0 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-focus:ml-1 peer-focus:-translate-y-3 peer-focus:px-1 peer-focus:text-sm">{props.name}</label>
  </div>;
}

export function MoneyInput(props: MoneyProps) {
  const [val, setVal] = useState<string>(props.value.toString());

  return <div className={"relative mb-4 mr-4 " + (props.inline ? " inline-block " : "")}>
    <input type="text" className="peer border placeholder:text-transparent rounded-md p-2" placeholder="name"
        onChange={(ev) => onChange(ev, props, setVal, false)} value={moneyFormatter(val)}  size={props.size ?? 14}/>
    <label htmlFor="text" className="absolute left-0 ml-1 -translate-y-3 bg-white px-1 text-sm duration-100 ease-linear peer-placeholder-shown:translate-y-0 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-focus:ml-1 peer-focus:-translate-y-3 peer-focus:px-1 peer-focus:text-sm">{props.name}</label>
  </div>;
}

export function PercentageInput(props: MoneyProps) {
  const [val, setVal] = useState<string>(props.value.toString());

  const size = props.name.length / 1.5;

  return <div className={"relative inline-block mr-4 " + (props.inline ? " inline-block " : "") + (" mt-" + (props.mt ?? 6))}>
    <input type="text" className={"peer border placeholder:text-transparent rounded-md p-2 bg-inherit border-" + props.color + "-900"} placeholder="name"
        onChange={(ev) => onChange(ev, props, setVal, true)} value={val + "%"} size={props.size ?? size}/>
    <label htmlFor="text" className={"absolute left-0 ml-1 -translate-y-3 px-1 text-sm duration-100 ease-linear peer-placeholder-shown:translate-y-0 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-focus:ml-1 peer-focus:-translate-y-3 peer-focus:px-1 peer-focus:text-sm bg-" + props.color + "-200 text-" + props.color + "-900" }>{props.name}</label>
  </div>;
}

export default function InvestmentInput(props: Props) {
  const defaultInvestment = {
    ...props.value
  };

  const [weights, setWeights] = useState<InvestmentWithWeights>(defaultInvestment);
  useEffect(() => {
    props.setter(weights);
  }, [weights])

  const allocTotal = weights.stocks.allocation + weights.bonds.allocation +
    weights.cash.allocation + weights.crypto.allocation +
    weights.other.allocation;

  const allocWarning = allocTotal != 100.0 ? (
    <div>
      <p className={"p-5 border rounded-md bg-yellow-200 border-yellow-400 text-yellow-800 mr-4 mb-4 inline-block"}>
        <FontAwesomeIcon icon={faTriangleExclamation} className="mr-2"></FontAwesomeIcon>
        Your allocation currently sums up to {allocTotal}%. Adjust your percentages below so they total 100%.
      </p>
    </div>
  ) : null;

  // A bit hacky... but... https://stackoverflow.com/a/69687962
  // border-red-200 border-red-600 border-red-900
  // border-green-200 border-green-600 border-green-900 text-green-900
  // border-orange-200 border-orange-600 border-orange-900 text-orange-900
  // border-slate-200 border-slate-600 border-slate-900 text-slate-900
  // border-blue-200 border-blue-600 border-blue-900 text-blue-900

  const marginTop = 4;
  return (
    <div className={"mb-6 mt-4"}>
      { allocWarning }
      <div className={"inline-block"}>
        <MoneyInput name={"Total"} setter={(val: number) => setWeights({ ...weights, total: val })} value={weights.total} />
        <MoneyInput name={"Monthly Saving"} setter={(val: number) => setWeights({ ...weights, saving: val })} value={weights.saving} />
      </div>
      <div className={"inline-block p-4 border rounded-md bg-red-200 border-red-600 mr-4 mb-4"}>
        <p className={"text-red-800"}>Stocks</p>
        <PercentageInput name={"Allocation"} setter={(val: number) => setWeights({ ...weights, stocks: { ...weights.stocks, allocation: val } })} value={weights.stocks.allocation}
          color="red" mt={marginTop} />
        <PercentageInput name={"Return"} setter={(val: number) => setWeights({ ...weights, stocks: { ...weights.stocks, return: val } })} value={weights.stocks.return}
          color="red" mt={marginTop} />
      </div>

      <div className={"inline-block p-4 border rounded-md bg-green-200 border-green-600 mr-4 mb-4"}>
        <p className={"text-green-800"}>Bonds</p>
        <PercentageInput name={"Allocation"} setter={(val: number) => setWeights({ ...weights, bonds: { ...weights.bonds, allocation: val } })} value={weights.bonds.allocation}
          color="green" mt={marginTop} />
        <PercentageInput name={"Return"} setter={(val: number) => setWeights({ ...weights, bonds: { ...weights.bonds, return: val } })} value={weights.bonds.return}
          color="green" mt={marginTop} />
      </div>

      <div className={"inline-block p-4 border rounded-md bg-orange-200 border-orange-600 mr-4 mb-4"}>
        <p className={"text-orange-800"}>Cash</p>
        <PercentageInput name={"Allocation"} setter={(val: number) => setWeights({ ...weights, cash: { ...weights.cash, allocation: val } })} value={weights.cash.allocation}
          color="orange" mt={marginTop} />
        <PercentageInput name={"Return"} setter={(val: number) => setWeights({ ...weights, cash: { ...weights.cash, return: val } })} value={weights.cash.return}
          color="orange" mt={marginTop} />
      </div>

      <div className={"inline-block p-4 border rounded-md bg-slate-200 border-slate-600 mr-4 mb-4"}>
        <p className={"text-slate-800"}>Crypto</p>
        <PercentageInput name={"Allocation"} setter={(val: number) => setWeights({ ...weights, crypto: { ...weights.crypto, allocation: val } })} value={weights.crypto.allocation}
          color="slate" mt={marginTop} />
        <PercentageInput name={"Return"} setter={(val: number) => setWeights({ ...weights, crypto: { ...weights.crypto, return: val } })} value={weights.crypto.return}
          color="slate" mt={marginTop} />
      </div>

      <div className={"inline-block p-4 border rounded-md bg-blue-200 border-blue-600 mr-4 mb-4"}>
        <p className={"text-blue-800"}>Other</p>
        <PercentageInput name={"Allocation"} setter={(val: number) => setWeights({ ...weights, other: { ...weights.other, allocation: val } })} value={weights.other.allocation}
          color="blue" mt={marginTop} />
        <PercentageInput name={"Return"} setter={(val: number) => setWeights({ ...weights, other: { ...weights.other, return: val } })} value={weights.other.return}
          color="blue" mt={marginTop} />
      </div>
    </div>
  );
}