import React, { PureComponent } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, AreaChart, Area, ComposedChart, ReferenceLine } from 'recharts';
import { moneyFormatter } from './utils';

const data = [
  {
    name: '27',
    pension: 4000,
    ISA: 2400,
    GIA: 2400,
  },
  {
    name: '28',
    pension: 3000,
    ISA: 1398,
    GIA: 2210,
  },
  {
    name: '29',
    pension: 2000,
    ISA: 9800,
    GIA: 2290,
  },
  {
    name: '30',
    pension: 2780,
    ISA: 3908,
    GIA: 2000,
  },
  {
    name: '31',
    pension: 1890,
    ISA: 4800,
    GIA: 2181,
  },
  {
    name: '32',
    pension: 2390,
    ISA: 3800,
    GIA: 2500,
  },
  {
    name: '33',
    pension: 3490,
    ISA: 4300,
    GIA: 2100,
  },
];

export type ChartDataPoint = {
  name: string;
  pension: number;
  ISA: number;
  GIA: number;
  total: number;
};

type Props = {
  data: ChartDataPoint[];
  swr: number;
  annualSpending: number;
}

export default function FireUkChart(props: Props) {
  let fireNumber = props.annualSpending / (props.swr/100)
  return (
    <div className='w-[102%] max-w-[83rem] h-[28rem] md:h-[35rem] lg:h-[50rem]'>
      <ResponsiveContainer>
        <ComposedChart
          width={1280}
          height={960}
          data={props.data}
          margin={{
            top: 5,
            right: 30,
            left: 25,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis tickFormatter={moneyFormatter} />
          <Legend />
          <Line type="monotone" dataKey="total" stroke="#8d8d8d" strokeDasharray="5 5" />
          <Area type="monotone" dataKey="pension" stroke="#82ca9d" fill="#82ca9d" stackId="2" />
          <Area type="monotone" dataKey="ISA" stroke="#8884d8" fill="#8884d8" stackId="1" /* activeDot={{ r: 8 }} */ />
          <Area type="monotone" dataKey="GIA" stackId="3" stroke="#ffc658" fill="#ffc658" />
          <ReferenceLine y={fireNumber} label="FIRE Number" stroke="blue" strokeDasharray="3 3" />
          <Tooltip formatter={moneyFormatter} />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
}